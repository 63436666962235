<script setup>
import { urlExpired } from '@/utilities/helpers';
import isNumber from 'lodash/isNumber';
import { computed, ref, watch } from 'vue';
import { vWhenVisible } from "@/directives/intersection";
import { fetchImage } from '@/utilities/caching.js';

const props = defineProps({
  style: { type: String, default: 'original' },
  width: { type: [Number, String], default: null },
  height: { type: [Number, String], default: null },
  urls: { type: Array, default: null },
  imageId: { type: null, default: null },
});

const owned = ref(false);
const visible = ref(false);
const loaded = ref(false);

const emit = defineEmits(['fetchUrls', 'loaded']);

const url = computed(() => props.urls?.find(r => r.style === props.style));

watch(url, checkLoad);

watch(visible, (v) => {
  if (v) {
    if (valid.value) {
      checkLoad();
    } else {
      emit('fetchUrls', props.imageId);
    }
  }
});

const imgUrl = ref(null);

const imgStyles = computed(() => {
  return {
    width: isNumber(props.width) ? `${props.width}px` : props.width,
    height: isNumber(props.height) ? `${props.height}px` : props.height,
    objectFit: 'contain',
    objectPosition: '50% 50%',
  };
});

const valid = computed(() => !urlExpired(url.value));

async function checkLoad() {
  if (url.value?.url) {
    const response = await fetchImage(url.value.url);
    if (response) {
      const blob = await response.blob();
      owned.value = true;
      imgUrl.value = URL.createObjectURL(blob);
      loaded.value = true;
    }
  }
}

function releaseUrl(e) {
  emit('loaded', { id: props.imageId, img: e.target });
  if (imgUrl.value && owned.value) {
    owned.value = false;
    URL.revokeObjectURL(imgUrl.value);
  }
}

function visibleCallback() {
  visible.value = true;
}
</script>

<template lang="pug">
img(
  v-if="valid && loaded"
  :src="imgUrl"
  :style="imgStyles"
  alt=""
  @load="releaseUrl($event)"
)
OSkeleton(
  v-else
  v-when-visible.once="{callback: visibleCallback}"
  :width="width + 'px'"
  :height="height + 'px'"
)
</template>

<style scoped>

</style>
