import isFunction from "lodash/isFunction";

const elementTracker = new Map();

const options = {
  root: null, // viewport
  rootMargin: '0px',
  threshold: 0.25,
};

const observer = new IntersectionObserver(observationHandler, options);

function observationHandler(entries, self) {
  for (const entry of entries) {
    const t = elementTracker.get(entry.target);
    if (!t) continue;

    if (entry.isIntersecting) {
      triggerElement(entry.target, t, self);
    }
  }
}

function trackElement(el, type, config) {
  let elConfig = elementTracker.get(el);
  if (!elConfig) {
    elConfig = {};
    observer.observe(el);
    elementTracker.set(el, elConfig);
  }
  elConfig[type] = config;
}

function untrackElement(el, type) {
  const elConfig = elementTracker.get(el);
  if (!elConfig) {
    return;
  }
  delete elConfig[type];
  if (Object.keys(elConfig).length === 0) {
    observer.observe(el);
    elementTracker.delete(el);
  }
}

function triggerElement(el, config, self) {
  const whenVisible = config.whenVisible;
  if (whenVisible) {
    if (isFunction(whenVisible.config.callback)) {
      whenVisible.config.callback();
    } else {
      console.warn("No callback function defined for ", el);
    }
    if (whenVisible.modifiers.once) {
      self.unobserve(el);
    }
  }
}

function bindingConfig(binding) {
  return {
    config: binding.value || {},
    modifiers: binding.modifiers || {},
  };
}

export const vWhenVisible = {
  mounted(el, binding, _vnode, _prevVnode) {
    // console.log('mounted', el, binding, _vnode, _prevVnode);
    trackElement(el, 'whenVisible', bindingConfig(binding));
  },
  unmounted(el, _binding, _vnode, _prevVnode) {
    // console.log('unmounted', el, binding, vnode, prevVnode);
    untrackElement(el, 'whenVisible');
  },
  updated(el, binding, _vnode, _prevVnode) {
    // console.log('updated', el, binding, _vnode, _prevVnode);
    const elConfig = elementTracker.get(el);
    if (!elConfig) {
      return;
    }
    if (!elConfig.whenVisible) {
      return;
    }
    elConfig.whenVisible = bindingConfig(binding);
  },
};
